import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsAccordionComponent } from './details-accordion.component';
import { DetailsAccordionItemComponent } from './details-accordion-item.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        DetailsAccordionComponent,
        DetailsAccordionItemComponent
    ],
    exports: [
        DetailsAccordionComponent,
        DetailsAccordionItemComponent
    ]
})
export class DetailsAccordionModule {
}
