import { AfterContentInit, ChangeDetectorRef, Component, ContentChildren, HostBinding, Input, OnDestroy, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { DetailsAccordionItemComponent } from './details-accordion-item.component';

@Component({
    selector: 'ins-details-accordion',
    template: `
        <ng-content></ng-content>
    `,
    styleUrls: ['details-accordion.component.scss'],
    host: {class: 'details-accordion'},
})

export class DetailsAccordionComponent implements AfterContentInit, OnDestroy {
    @ContentChildren(DetailsAccordionItemComponent) accordionItems: QueryList<DetailsAccordionItemComponent>;
    @HostBinding('class.details-accordion--icon') @Input() icon: string;
    @HostBinding('class.details-accordion--ondark') @Input() onDark: boolean;
    @Input() openOnlySelected: boolean = false;

    private subscriptions = new Subscription();
    constructor (
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngAfterContentInit(): void {
        // initial load
        this.getItem();

        // load changes
        this.subscriptions.add(
            this.accordionItems.changes.subscribe(() => this.getItem()),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getItem(): void {
        this.accordionItems.toArray().forEach((accordionItem) => {
            accordionItem.openOnlySelected = this.openOnlySelected;
            this.setIcons(accordionItem);
            const click = accordionItem.clickItem.subscribe(() => {
                this.openPanel(accordionItem);
            });

            this.subscriptions.add(click);
        });
    }

    private setIcons(item: DetailsAccordionItemComponent): void {
        item.icon = this.icon;
        if (this.icon === 'number') {
            item.number = true;
        }
    }

    private openPanel(detailsAccordionItemComponent: DetailsAccordionItemComponent): void {
        if (this.openOnlySelected) {
            const accordionItemOpened = detailsAccordionItemComponent.open;
            // close all panels
            this.accordionItems.toArray().forEach((accordion) => accordion.open = false);

            // open the selected panel
            detailsAccordionItemComponent.open = !accordionItemOpened;
        }
        this.changeDetectorRef.markForCheck();
    }
}
