<details #details>
    <summary role="button" (click)="onClickItem($event)">
        <div
            class="details-accordion__title"
            [class.details-accordion__title--no-arrow]="icon === 'plus'"
            [class.details-accordion__title--ondark]="onDark"
        >
            <svg *ngIf="icon === 'check'" class="details-accordion__icon svg-icon svg-icon--xs">
                <use href="assets/icons-ui.svg#check-closed"></use>
            </svg>
            <svg
                *ngIf="icon === 'plus'"
                class="details-accordion__icon details-accordion__icon--plus svg-icon svg-icon--xs"
            >
                <use href="assets/icons-ui.svg#close"></use>
            </svg>
            <ng-content select="[title]"></ng-content>
        </div>
    </summary>
    <div class="details-accordion__content" [ngClass]="onDark ? 'details-accordion__content--ondark' : ''">
        <ng-content select="[content]"></ng-content>
    </div>
</details>
