import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'ins-details-accordion-item',
    templateUrl: './details-accordion-item.component.html',
    styleUrls: ['details-accordion-item.component.scss'],
    host: {
        class: 'details-accordion__item',
        '[class.details-accordion__item--number]' : 'number',
    },
})

export class DetailsAccordionItemComponent {
    @ViewChild('details', {static: true}) details: ElementRef;
    @Output() readonly clickItem: EventEmitter<void> = new EventEmitter();
    @Input() openOnlySelected: boolean = false;
    @Input() public onDark: boolean = false;

    _open: boolean;
    get open(): boolean {
        return this._open;
    }
    @Input() set open(value: boolean) {
        this._open = value;
        this.toggleAttribute(value);
    }

    public icon: string;
    public number: boolean;

    constructor (
        private changeDetectorRef: ChangeDetectorRef,
        private renderer: Renderer2,
    ) {}

    public onClickItem(event: MouseEvent): void {
        this.clickItem.emit();
        if (this.openOnlySelected) {
            event.preventDefault();
        }
    }

    private toggleAttribute(value: boolean): void {
        if (!value) {
            this.renderer.removeAttribute(this.details.nativeElement, 'open');
        } else {
            this.renderer.setAttribute(this.details.nativeElement, 'open', 'true');
        }

        this.changeDetectorRef.markForCheck();
    }
}
